define("plutof/components/taxonomy/taxon/view/taxon-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@taxonDescription}}
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{#view.attr "language"}}
                  {{@taxonDescription.language.representation}}
              {{/view.attr}}
  
              {{#view.attr "created_by"}}
                  {{@taxonDescription.created_by.representation}}
              {{/view.attr}}
          </div>
  
          <div>
              {{#link-to "taxon-description.view" @taxonDescription.id}}
                  {{plutof-labelc "taxondescription.description"}}
              {{/link-to}}
  
              <LongText
                  @text={{@taxonDescription.description}}
                  @text-height="height-4" />
          </div>
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "7/TR6oGS",
    "block": "{\"symbols\":[\"view\",\"@taxonDescription\"],\"statements\":[[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/taxonomy/taxon/view/taxon-description.hbs' @ L7:C15) \"],null],\"language\"],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"language\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/taxonomy/taxon/view/taxon-description.hbs' @ L11:C15) \"],null],\"created_by\"],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"created_by\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"taxon-description.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[\"taxondescription.description\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,\"long-text\",[],[[\"@text\",\"@text-height\"],[[32,2,[\"description\"]],\"height-4\"]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"-assert-implicit-component-helper-argument\",\"component\",\"link-to\"]}",
    "moduleName": "plutof/components/taxonomy/taxon/view/taxon-description.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});