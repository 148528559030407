define("plutof/templates/components/plutof-select/direct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4JAP3uem",
    "block": "{\"symbols\":[\"xs\",\"option\"],\"statements\":[[6,[37,10],null,[[\"value\",\"action\",\"class\"],[[35,7],[30,[36,9],[[32,0],[30,[36,8],[[35,7]],null]],null],\"form-control\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('plutof/templates/components/plutof-select/direct.hbs' @ L3:C11) \"],null]],[[\"value\"],[null]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('plutof/templates/components/plutof-select/direct.hbs' @ L9:C11) \"],null]],[[\"value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"prompt\",\"if\",\"content\",\"-track-array\",\"each\",\"value\",\"mut\",\"action\",\"x-select\"]}",
    "moduleName": "plutof/templates/components/plutof-select/direct.hbs"
  });
});