define("plutof/components/taxonomy/taxon/view/hybrid", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isHybrid}}
      <GenericViews::Record @record={{@taxon}} as |view|>
          <Layout::RegularGrid @columns={{2}}>
              {{view.link "hybrid_parent_1" route="taxonomy.view"}}
              {{view.link "hybrid_parent_2" route="taxonomy.view"}}
              {{view.field "notho_rank"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  {{/if}}
  
  */
  {
    "id": "37VCnufx",
    "block": "{\"symbols\":[\"view\",\"@taxon\"],\"statements\":[[6,[37,2],[[32,0,[\"isHybrid\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"link\"]],\"expected `view.link` to be a contextual component but found a string. Did you mean `(component view.link)`? ('plutof/components/taxonomy/taxon/view/hybrid.hbs' @ L4:C14) \"],null],\"hybrid_parent_1\"],[[\"route\"],[\"taxonomy.view\"]]]],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"link\"]],\"expected `view.link` to be a contextual component but found a string. Did you mean `(component view.link)`? ('plutof/components/taxonomy/taxon/view/hybrid.hbs' @ L5:C14) \"],null],\"hybrid_parent_2\"],[[\"route\"],[\"taxonomy.view\"]]]],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"field\"]],\"expected `view.field` to be a contextual component but found a string. Did you mean `(component view.field)`? ('plutof/components/taxonomy/taxon/view/hybrid.hbs' @ L6:C14) \"],null],\"notho_rank\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"if\"]}",
    "moduleName": "plutof/components/taxonomy/taxon/view/hybrid.hbs"
  });
  let TaxonViewHybrid = (_dec = Ember.computed('args.taxon.{hybrid_parent_1,hybrid_parent_2}'), _class = class TaxonViewHybrid extends _component.default {
    get isHybrid() {
      const taxon = this.args.taxon;
      if (Ember.isNone(taxon)) {
        return false;
      }
      const hasParent1 = !Ember.isNone(taxon.get('hybrid_parent_1.id'));
      const hasParent2 = !Ember.isNone(taxon.get('hybird_parent_2.id'));
      const hasNothoRank = !Ember.isNone(taxon.get('notho_rank'));
      return hasParent1 || hasParent2 || hasNothoRank;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isHybrid", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isHybrid"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonViewHybrid);
});