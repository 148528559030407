define("plutof/components/conservation/red-list/-edit/general-data", ["exports", "@glimmer/component", "plutof/utils/validations"], function (_exports, _component, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <GenericForms::Record @record={{@redlist}} as |form|>
          {{form.bind "name" valid=this.nameValid}}
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "I9Yhytna",
    "block": "{\"symbols\":[\"form\",\"@redlist\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-forms/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"bind\"]],\"expected `form.bind` to be a contextual component but found a string. Did you mean `(component form.bind)`? ('plutof/components/conservation/red-list/-edit/general-data.hbs' @ L3:C10) \"],null],\"name\"],[[\"valid\"],[[32,0,[\"nameValid\"]]]]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "moduleName": "plutof/components/conservation/red-list/-edit/general-data.hbs"
  });
  let RedListGeneralData = (_dec = Ember.computed.notEmpty('args.redlist.name'), _class = class RedListGeneralData extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "nameValid", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "nameValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RedListGeneralData);
});