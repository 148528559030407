define("plutof/components/sample/event-fields-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@event}}
      @testMarker="event-fields-view"
      class="event-fields-view"
      as |view|
  >
      {{view.field "event_id"}}
      {{view.field "timespan_begin"}}
      {{view.field "timespan_end"}}
  
      {{#view.attr "description"}}
          <LongText
              @text={{@event.description}}
              @text-height="height-2"
              @tagName="span" />
      {{/view.attr}}
  
      <Sample::MeasurementFields
          @object={{@event}}
          @mainform={{@externalMainform}} />
  </GenericViews::Record>
  
  */
  {
    "id": "cuvjUYUz",
    "block": "{\"symbols\":[\"view\",\"@event\",\"@externalMainform\"],\"statements\":[[8,\"generic-views/record\",[[24,0,\"event-fields-view\"]],[[\"@record\",\"@testMarker\"],[[32,2],\"event-fields-view\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"field\"]],\"expected `view.field` to be a contextual component but found a string. Did you mean `(component view.field)`? ('plutof/components/sample/event-fields-view.hbs' @ L7:C6) \"],null],\"event_id\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"field\"]],\"expected `view.field` to be a contextual component but found a string. Did you mean `(component view.field)`? ('plutof/components/sample/event-fields-view.hbs' @ L8:C6) \"],null],\"timespan_begin\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"field\"]],\"expected `view.field` to be a contextual component but found a string. Did you mean `(component view.field)`? ('plutof/components/sample/event-fields-view.hbs' @ L9:C6) \"],null],\"timespan_end\"],null]],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/sample/event-fields-view.hbs' @ L11:C7) \"],null],\"description\"],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"long-text\",[],[[\"@text\",\"@text-height\",\"@tagName\"],[[32,2,[\"description\"]],\"height-2\",\"span\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"sample/measurement-fields\",[],[[\"@object\",\"@mainform\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "moduleName": "plutof/components/sample/event-fields-view.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});